import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";


export default class Player extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillReceiveProps(nextProps) {
        
    }


    render() {
        return (
            <div className={`${styles.player} ${this.props.left ? "" : styles.reverse}`}>
                <div id={`potato-${this.props.player.id}`} className={`${styles.potato}`}>
                    <Lottie
                        options={getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={`${styles.name} ${this.props.player.name.length < 5 ? styles.large : this.props.player.name.length < 8 ? styles.medium : styles.small}`}>{this.props.player.name}</div>
            </div>
        )
    }
}