import lobbyFullscreen from "../images/Host/lobby_fullscreen.png";
import lobbyHelp from "../images/Host/lobby_help.png";
import lobbyMuted from "../images/Host/lobby_muted.png";
import lobbyUnmuted from "../images/Host/lobby_unmuted.png";
import dingerBase from "../images/Dinger/dinger-base.png";
import dingerTop from "../images/Dinger/dinger-top.png";
import dingerDetail from "../images/Dinger/dinger-detail.png";
import bigWatchButton from "../images/BigWatchButton.png";
import bigWatchFace from "../images/BigWatchFace.png";
import checkstrip from "../images/checkstrip.png";
import flagBlack from "../images/flag-black.png";
import flagPink from "../images/flag-pink.png";
import logo from "../images/logo.png";
import mobileBlackBG from "../images/mobile-black-BG.png";
import mobilePinkBG from "../images/mobile-pink-BG.png";
import questionMark from "../images/questionMark.png";
import roundBG from "../images/round-BG.png";
import stopwatchButton from "../images/StopwatchButton.png";
import stopwatchFace from "../images/StopwatchFace.png";
import trophy from "../images/trophy.png";
import warningBlack from "../images/warning-black.png";
import warningPink from "../images/warning-pink.png";



const images = [
    lobbyFullscreen,
    lobbyHelp,
    lobbyMuted,
    lobbyUnmuted,
    dingerBase,
    dingerTop,
    dingerDetail,
    bigWatchButton,
    bigWatchFace,
    checkstrip,
    flagBlack,
    flagPink,
    logo,
    mobileBlackBG,
    mobilePinkBG,
    questionMark,
    roundBG,
    stopwatchButton,
    stopwatchFace,
    trophy,
    warningBlack,
    warningPink
];

const fonts = [
    { font: "Interstate", path: `https://${window.location.host}/fonts/Interstate-Regular.otf` },
    { font: "Logkey", path: `https://${window.location.host}/fonts/Logkey.woff2` },
];

export default {
    images,
    fonts,
};