import React, { Component } from 'react';
import Lottie from 'react-lottie';
import "animate.css";

import getAvatarById from "constants/avatars";
import styles from "components/DefaultViewStyles.module.scss";
import ErrorModal from './Utility/ErrorModal';

const animations = [
    "bounce",
    "rubberBand",
    "shakeX",
    "shakeY",
    "headShake",
    "swing",
    "tada",
    "wobble",
    "jello",
    "heartBeat",
];

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

export default class DefaultView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            room: props.room,
            player: props.player,
            tickedSkipTutorial: false,
            showStartWarning: false,
        };
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ player: nextProps.player, });
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    doAnimation = () => {
        let animation = this.getRandomElement(animations);
        this.state.room.send("animate_potato", { animation: animation, });
        if (document.getElementById(`potato`)) {
            this.animateCSS(`#potato`, animation);
        }
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    clickStartGame = (confirm = false, skipTutorial) => {
        let allConnected = true;
        for (let i = 0; i < this.props.players.length; i++) {
            let value = this.props.players[i].connected;
            if (value === false) allConnected = false;
        }
        if (skipTutorial !== null) {
            this.setState({ tickedSkipTutorial: skipTutorial });
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room.send("begin_game", { skipTutorial: skipTutorial, });
        }
    }

    clickGoToLobby = () => {
        this.state.room.send("change_game", {});
    }

    clickPlayAgain = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.props.players.length; i++) {
            let value = this.props.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room.send("ycsu_new_game", {});
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmStartGame = () => {
        this.clickStartGame(true, this.state.tickedSkipTutorial);
    }

    confirmPlayAgain = () => {
        this.clickPlayAgain(true);
    }

    getRuleList() {
        const team = { ...this.props.teams.find(x => x.id === this.props.describingTeamId) }
        const myTeam = this.props.teams[this.props.player.ycsuData.teamIndex];
        if (!team || !team.rules || myTeam.id !== this.props.describingTeamId) return;

        let rules = [];
        team.rules.forEach((x) => {
            if (x != null) {
                rules.push(<div className={`${styles.rule}`}>{`${x.ruleText1} ${x.blockText} ${x.ruleText2}`}</div>)
            }
        });
        return <div className={styles.ruleList}>
            <div className={styles.subText}>Your teams rules:</div>
            {rules}
        </div>
    }

    getDisplayMessage = () => {
        let message = "";
        let dinger = this.props.players.find((x) => x.ycsuData.isDinger);
        let describer = this.props.players.find((x) => x.ycsuData.isDescriber);
        let team = this.props.teams[this.props.player.ycsuData.teamIndex];
        switch (this.props.clientDescriberState) {
            case "_ROUND":
                if (team) {
                    let describer = this.props.players.find((x) => x.ycsuData.isDescriber);
                    if (team.id === this.props.describingTeamId) {
                        if (describer && describer.id === this.props.player.id) {
                            message = "You are the describer, get ready!";
                        } else {
                            message = `${describer.name} is about to describe for your team, get ready!`;
                        }
                    } else {
                        let dinger = this.props.players.find((x) => x.ycsuData.isDinger);
                        if (dinger && dinger.id === this.props.player.id) {
                            message = "You are the dinger, get ready to listen out for rule breaks!";
                        } else {
                            message = `Get ready to shout when the other team breaks a rule!`;
                        }
                    }
                }
                break;
            case "_RULE_BREAK":
                if ((dinger && dinger.id === this.props.player.id) || (describer && describer.id === this.props.player.id)) {
                    message = "";
                } else {
                    message = `${describer.name} and ${dinger.name} need to agree on the amount of rule breaks!`;
                }
                break;
            case "_CORRECT_CARD":
                if ((dinger && dinger.id === this.props.player.id) || (describer && describer.id === this.props.player.id)) {
                    message = "";
                } else {
                    message = `${describer.name} and ${dinger.name} need to agree on the amount of card pairs guessed!`;
                }
                break;
            case "_NEW_RULE":
                if (this.props.newRuleTeamId === team.id) {
                    message = "The other team are voting on a new rule for your team!";
                } else {
                    message = "Your team is voting on a new rule!";
                }
                break;
            case "_WIN_DECIDER":
                if ((dinger && dinger.id === this.props.player.id) || (describer && describer.id === this.props.player.id)) {
                    message = "";
                } else {
                    message = `${describer.name} and ${dinger.name} need to agree on the score earned to win!`;
                }
                break;
            default:
                message = ``;
                break;
        }
        return message;
    }

    render() {
        const team = this.props.teams[this.state.player.ycsuData.teamIndex];

        return (
            <div className={`${styles.defaultSection} ${this.state.player.ycsuData.teamIndex === 0 ? styles.teamA : styles.teamB}`}>
                <div className={styles.playerSection}>
                    {
                        this.state.player && this.state.player.id &&
                        <React.Fragment>
                            <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                                <Lottie
                                    options={getAvatarById(this.state.player.avatar).idleAnim}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                                <div className={`${styles.text} ${this.state.player.name.length > 4 ? this.state.player.name > 8 ? styles.small : styles.medium : styles.large}`}>{this.state.player.name}</div>
                                {
                                    team && team.id &&
                                    <>
                                        <div className={`${styles.text} ${styles.tiny}`}>{team.id}</div>
                                        <div className={styles.subText}>Score: {team.score}</div>
                                    </>
                                }
                        </React.Fragment>
                    }
                </div>
                {
                    this.state.player && this.state.player.ycsuData.teamIndex != null && this.state.player.ycsuData.teamIndex >= 0 && ([GameStates.Loading, GameStates.EndGame].includes(this.props.gameState) ? !this.props.player.primaryPlayer : true) &&
                    <div className={`${styles.teamInfo} `}>
                            <div className={styles.subText}>{this.getDisplayMessage()}</div>
                            {/*<div className={styles.largeLetter}>{this.state.player.ycsuData.teamIndex === 0 ? "A" : "B"}</div>*/}
                            {
                                this.getRuleList()
                            }
                    </div>
                }
                {
                    this.state.player && this.state.player.primaryPlayer && [GameStates.Loading, GameStates.EndGame].includes(this.props.gameState) && this.props.hostConnected &&
                    <React.Fragment>
                        {
                            this.props.gameState === GameStates.Loading &&
                            <div className={styles.buttons}>
                                <div className={styles.button} onClick={() => this.clickStartGame(false, true)}>Start Game</div>
                                <div className={styles.button} onClick={() => this.clickStartGame(false, false)}>Show Tutorial</div>
                                <div className={`${styles.button} ${styles.alt}`} onClick={this.clickGoToLobby}>Go To Lobby</div>
                            </div>
                        }
                        {
                            this.props.gameState === GameStates.EndGame &&
                            <div className={styles.buttons}>
                                <div className={`${styles.button}`} onClick={() => this.clickPlayAgain()}>Play Again</div>
                                        <div className={`${styles.button}  ${styles.alt}`} onClick={this.clickGoToLobby}>Different Game</div>
                            </div>
                        }
                    </React.Fragment>
                }
                {
                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.props.gameState) && this.props.hostConnected &&
                    <ErrorModal
                        title={"Are you ready to play?"}
                        styles={"d-flex"}
                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                        callback={this.closeStartWarning}
                        callbackText={"No"}
                        callback2={this.props.gameState === GameStates.Loading ? this.confirmStartGame : this.confirmPlayAgain}
                        callbackText2={"Yes"}
                    />
                }
            </div>
        )
    }
}