import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/TutorialStyles.module.scss';

import warningPink from "images/warning-pink.png";
import warningBlack from "images/warning-black.png";
import flagBlack from "images/flag-black.png";

var audio = {

};


export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: false,
            stepOneTitleOne: "YOU CAN'T",
            stepOneTitleTwo: `SAY "UMM"`,
            stepOneTextOne: "Here's how to play...",
            stepOnePlayers: [
                {
                    avatar: 2,
                    name: "GHOST"
                },
                {
                    avatar: 8,
                    name: "ROCKSTAR"
                },
                {
                    avatar: 10,
                    name: "CAT"
                },
                {
                    avatar: 6,
                    name: "COWBOY"
                }
            ],

            showStepTwo: false,
            stepTwoPlayers: [
                {
                    name: "GHOST",
                    ycsuData: {
                        teamIndex: 0
                    }
                },
                {
                    name: "ROCKSTAR",
                    ycsuData: {
                        teamIndex: 0
                    }
                },
                {
                    name: "CAT",
                    ycsuData: {
                        teamIndex: 1
                    }
                },
                {
                    name: "COWBOY",
                    ycsuData: {
                        teamIndex: 1
                    }
                },
            ],

            showScoreColumn: false,
            scoreColumnTeam: {
                id: 0,
                score: 15,
            },
            newRuleZones: [4, 8],
            dangerZoneStart: 12,
            pointsToWin: 20,

            showStepEight: false,
        };
    }

    componentDidMount() {
        //this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        //this.preloadAudio();
        //Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    async doStepOne() {
        this.setState({ showStepOne: true, });
        await this.sleep(3000);
        this.setState({ showStepOne: false, });
        await this.sleep(1500);
        this.doStepTwo();
    }

    async doStepTwo() {
        this.setState({ showStepTwo: true, });
        await this.sleep(6500);
        this.setState({ showStepTwo: false });
        await this.sleep(1000);
        this.doStepThree();
    }

async doStepThree() {
        this.setState({ showStepThree: true, });
        await this.sleep(6500);
        this.setState({ showStepThree: false, });
        await this.sleep(1000);
        this.doStepFour();
    }

async doStepFour() {
        this.setState({ showStepFour: true, });
        await this.sleep(6500);
        this.setState({ showStepFour: false, });
        await this.sleep(1000);
        this.doStepFive();
    }

async doStepFive() {
        this.setState({ showStepFive: true, });
        await this.sleep(8000);
        this.setState({ showStepFive: false, });
        await this.sleep(1000);
        this.doStepSix();
    }

async doStepSix() {
        this.setState({ showStepSix: true, });
        await this.sleep(8000);
        this.setState({ showStepSix: false, });
        await this.sleep(1000);
        this.doStepSeven();
    }

async doStepSeven() {
        this.setState({ showStepSeven: true, showScoreColumn: true});
        await this.sleep(8000);
        this.setState({ showStepSeven: false, });
        await this.sleep(1000);
        this.doStepEight();
    }

    async doStepEight() {
        this.setState({ showStepEight: true, });
        await this.sleep(8000);
        this.setState({ showStepEight: false, showScoreColumn: false, });
        await this.sleep(1000);
        this.doStepNine();
    }

async doStepNine() {
        this.setState({ showStepNine: true, });
        await this.sleep(4000);
    this.setState({ showStepNine: false });
    this.props.room.send("end_tutorial", {});
}


    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    getScoreColumn(teamIndex) {
        const team = this.state.scoreColumnTeam;
        if (!team) return;

        let scoreBoxes = [];
        let dangerZoneIndex = 0;

        for (let i = 1; i <= this.state.pointsToWin; i++) {
            if (i >= this.state.dangerZoneStart) dangerZoneIndex++;

            scoreBoxes.push(<div key={`scorebox-${team.id}-${i}-${team.score >= i}`} className={`${styles.scoreBox} ${i >= this.state.dangerZoneStart && styles.dangerZone} ${team.score >= this.state.dangerZoneStart && styles.pulse}`}>
                <div className={`${styles.innerBox} ${team.score >= i && styles.highlight} ${this.state.newRuleZones.includes(i) && styles.ruleZone} ${teamIndex === 0 ? styles.pink : styles.black}`}>
                    <div className={`${styles.scoreNum} ${teamIndex === 0 ? styles.pink : styles.black}`}>{i}</div>
                </div>
                {
                    this.state.newRuleZones.includes(i) &&
                    <div className={styles.newRuleMark}>
                        <div className={styles.arrow}></div>
                        <div className={styles.ruleText}>NEW RULE</div>
                    </div>
                }
                {
                    dangerZoneIndex === Math.ceil((this.state.pointsToWin - this.state.dangerZoneStart) / 2) &&
                    <div className={styles.dangerZoneMark}>
                        <img src={teamIndex == 0 ? warningPink : warningBlack} className={styles.warning} />
                        <div className={styles.zoneText}>DANGER ZONE</div>
                    </div>
                }
                <div key={`${teamIndex}-score-${i}-${team.score >= i ? "true" : "false"}`} className={`${styles.pointGained} ${teamIndex === 0 ? styles.teamA : styles.teamB}`}>+1</div>
            </div>);
        }

        return scoreBoxes;
    }


    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}> 
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.genericTitle}  ${styles.bigger} ${styles.animate}`}>{this.state.stepOneTitleOne}</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>{this.state.stepOneTitleTwo}</div>
                    <div className={`${styles.subText} ${styles.animate}`}>{this.state.stepOneTextOne}</div>
                    <div className={`${styles.votingPlayerSection} ${styles.animate} `}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.votingPlayer} ${styles.left}`}>
                                    <div className={`${styles.potato}`}>
                                        <Lottie
                                            options={getAvatarById(x.avatar).idleAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                    <div className={`${styles.name}`}>{x.name}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={`${styles.chooseTeamSection} ${this.state.showStepTwo && styles.show}`}>
                    <div className={`${styles.teamTitleGroup} ${styles.show}`}>
                        <div className={`${styles.genericTitle} ${styles.show}`}>FIRST PICK YOUR</div>
                        <div className={`${styles.genericTitle} ${styles.bigger} ${styles.show}`}>TEAMS</div>
                        <div className={`${styles.subText} ${styles.show}`}>You'll need at least 2 players per team.</div>
                    </div>
                    <div className={`${styles.teamSection} ${styles.teamA} `}>
                        <div className={styles.title}>TEAM A</div>
                        <div className={styles.teamBox}>
                            {
                                this.state.stepTwoPlayers.filter((x, i) => x.ycsuData.teamIndex === 0).map((y, i) => {
                                    return <div className={`${styles.teamMember} ${styles.animate} ${y.name.length > 8 && styles.small}`}>{y.name}</div>
                                })
                            }
                        </div>
                    </div>
                    <div className={`${styles.teamSection} ${styles.teamB}`}>
                        <div className={styles.title}>TEAM B</div>
                        <div className={styles.teamBox}>
                            {
                                this.state.stepTwoPlayers.filter((x, i) => x.ycsuData.teamIndex === 1).map((y, i) => {
                                    return <div className={`${styles.teamMember} ${y.name.length > 8 && styles.small}`}>{y.name}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>EACH ROUND, ONE TEAM WILL</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>DESCRIBE</div>
                    <div className={`${styles.subText} ${styles.animate}`}>One player will be the designated describer...</div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>THEIR JOB IS TO DESCRIBE THE CARDS</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>ON THEIR PHONE</div>
                    <div className={`${styles.subText} ${styles.animate}`}>The rest of the team need to guess what they are.</div>
                </div>
<div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>BUT YOU CAN'T</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}> SAY "UMM"</div>
                    <div className={`${styles.subText} ${styles.animate}`}>If you do, the other team gains a point!</div>
</div>
                <div className={`${styles.stepSix} ${styles.step} ${this.state.showStepSix && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>THE OTHER TEAM WILL DING</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>THE BELL</div>
                    <div className={`${styles.subText} ${styles.animate}`}>Whenever the describer breaks the rules.</div>
</div>
                <div className={`${styles.scoreColumn} ${this.state.showScoreColumn && styles.show}`}>
                    {
                        this.getScoreColumn(1)
                    }
                    <img src={flagBlack} className={`${styles.flag}`} />
                </div>
                <div className={`${styles.stepSeven} ${styles.step} ${this.state.showStepSeven && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>THROUGHOUT THE GAME YOU'LL GET</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>NEW RULES</div>
                    <div className={`${styles.subText} ${styles.animate}`}>So remember to follow them if you want to win!</div>
                </div>
                <div className={`${styles.stepEight} ${styles.step} ${this.state.showStepEight && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>AT 12 POINTS YOU'LL ENTER THE</div>
                    <div className={`${styles.genericTitle} ${styles.medium} ${styles.animate}`}>DANGER</div>
                    <div className={`${styles.genericTitle} ${styles.medium} ${styles.animate}`}>ZONE</div>
                    <div className={`${styles.subText} ${styles.animate}`}>Meaning every team member must follow the rules!</div>
                </div>
                <div className={`${styles.stepNine} ${styles.step} ${this.state.showStepNine && styles.show}`}>
                    <div className={`${styles.genericTitle} ${styles.animate}`}>GOOD LUCK AND</div>
                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>HAVE FUN!</div>
                </div>
            </div>
        );
    }
}