import React, { Component } from 'react';
import { Route } from 'react-router';

import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import DefaultView from "components/DefaultView";

import styles from 'components/ClientStyles.module.scss';
import getAvatarById from '../constants/avatars';

import MobilePinkBG from 'images/mobile-pink-BG.png';
import MobileBlackBG from 'images/mobile-black-BG.png';
import stopwatchButton from "images/StopwatchButton.png";
import stopwatchFace from "images/StopwatchFace.png";
import dingerBase from "images/Dinger/dinger-base.png";
import dingerDetail from "images/Dinger/dinger-detail.png";
import dingerTop from "images/Dinger/dinger-top.png";
import questionMark from "images/questionMark.png";

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    SelectTeamMethod: "select_team_method",
    SelectTeams: "select_teams",
    ConfirmTeams: "confirm_teams",
    ReadyCheck: "ready_check",
    PlayingIdle: "playing_idle",
    Playing: "playing",
    RuleBreakVote: "rule_break_vote",
    CorrectCardVote: "correct_card_vote",
    NewRuleVote: "new_rule_vote",
    WinDecider: "win_decider",

    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "cant_say_umm";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            //room: {},
            myId: null,
            gameState: GameStates.Loading,
            //gameState: GameStates.Playing,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            reconnectionToken: "",
            hostConnected: false,
            players: [
                //{
                //    id: 1,
                //    name: "SCOTT",
                //    avatar: 3,
                //    ycsuData: {
                //        teamIndex: 1,
                //        isDinger: true,
                //    }
                //},
                //{
                //    id: 1,
                //    name: "BOB",
                //    avatar: 1,
                //    ycsuData: {
                //        teamIndex: 1,
                //    }
                //},
                //{
                //    id: 1,
                //    name: "RICHARD",
                //    avatar: 2,
                //    ycsuData: {
                //        teamIndex: 0,
                //        isDescriber: true,
                //    }
                //}
            ],
            //player: {
            //    id: 1,
            //    name: "SCOTT",
            //    avatar: 3,
            //    ycsuData: {
            //        teamIndex:1,
            //        isDinger: true,
            //        isDescriber: false
            //    }
            //},
            player: {
                ycsuData: {
                }
            },
            //teams: [
            //    {
            //    id: "Team A",
            //    rules: [
            //        {
            //            ruleText: "You can't say",
            //            blockText: "Umm"
            //        },
            //        {
            //            ruleText: "Clap your feet together after every",
            //            blockText: "correct guess"
            //        }
            //    ],
            //    currentCards: [
            //        {
            //            cardText: "Spooky",
            //        },
            //        {
            //            cardText: "Toaster",
            //        }
            //    ]
            //},
            //    {
            //        id: "Team B",
            //    }
            //],
            teams: [],
            describingTeamId: "",
            //describingTeamId: "Team A",
            currentRulesVote: [],

            submitActive: true,
            dingCount: 0,
            voteRuleId: null,
            clientDescriberState: "_DEFAULT",

            gotLocationPing: true,
            connectionIssue: false,
        };
        this.locationCheckInterval = null;
    }

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    getRenderView() {
        switch (this.state.gameState) {
            case GameStates.Tutorial:
                if (!this.state.player.votedSkip) {
                    return this.getTutorialView();
                }
                break;
            case GameStates.SelectTeamMethod:
                if (this.state.player.primaryPlayer) {
                    return this.getSelectTeamMethodView();
                }
                break;
            case GameStates.SelectTeams:
                return this.getSelectTeamView();
                break;
            case GameStates.ConfirmTeams:
                if (this.state.player.primaryPlayer) {
                    return this.getConfirmTeamView();
                }
                break;
            case GameStates.ReadyCheck:
                if ((this.state.player.ycsuData.isDescriber || this.state.player.ycsuData.isDinger) && this.state.player.ycsuData.ready === false) {
                    return this.getReadyUpView();
                }
                break;
            case GameStates.Playing:
                if (this.state.teams[this.state.player.ycsuData.teamIndex]?.id === this.state.describingTeamId) {
                    if (this.state.player.ycsuData.isDescriber) {
                        return this.getDescriberView()
                    } else {
                        return this.getGuessingView()
                    }
                } else {
                    return this.getDingerView();
                }
                break;
            case GameStates.RuleBreakVote:
                if (this.state.player.ycsuData.isDescriber || this.state.player.ycsuData.isDinger) {
                    return this.getRuleBreakVoteView();
                }
                break;
            case GameStates.CorrectCardVote:
                if (this.state.player.ycsuData.isDescriber || this.state.player.ycsuData.isDinger) {
                    return this.getCorrectCardVoteView();
                }
                break;
            case GameStates.NewRuleVote:
                if (this.state.teams[this.state.player.ycsuData.teamIndex]?.id !== this.state.newRuleTeamId && this.state.player.ycsuData.ready === false) {
                    return this.getNewRuleVoteView()
                }
                break;
            case GameStates.WinDecider:
                if (this.state.player.ycsuData.isDescriber || this.state.player.ycsuData.isDinger) {
                    return this.getWinDeciderView();
                }
                break;
            default:
                return this.getDefaultView();
        }
        return this.getDefaultView();
    }

    getDefaultView() {
        return <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} teams={this.state.teams} clientDescriberState={this.state.clientDescriberState} describingTeamId={this.state.describingTeamId} gameState={this.state.gameState} newRuleTeamId={this.state.newRuleTeamId} />
    }

    getTutorialView() {
        return <div className={styles.buttons} onClick={() => this.skipTutorial()}>
            <div className={styles.mainButton}>Skip Tutorial</div>
        </div>
    }

    getSelectTeamMethodView() {
        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                    <Lottie
                        options={getAvatarById(this.state.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={styles.text}>{this.state.player.name}</div>
            </div>
            <div className={styles.subText}>Choose the team selection method:</div>
            <div className={styles.buttons}>
                <button className={`${styles.mainButton}`} onClick={() => this.clickTeamMethod("manual")}>Manually Select</button>
                <button className={`${styles.mainButton} ${styles.alt}`} onClick={() => this.clickTeamMethod("random")}>Select Randomly</button>
            </div>
        </div>
    }

    clickTeamMethod(type) {
        this.state.room.send("selected_team_type", { type });
    }

    getSelectTeamView() {
        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                    <Lottie
                        options={getAvatarById(this.state.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={styles.text}>{this.state.player.name}</div>
            </div>
            <div className={styles.subText}>Pick a team to be on.</div>
            <div className={styles.teamList}>
                {
                    this.state.teams.map((x, i) => {
                        return <div className={`${styles.teamOption} ${this.state.player.ycsuData.teamIndex === i && styles.selected}`} onClick={() => this.clickTeamOption(x.id)}>
                            <div className={styles.title}>{x.id}</div>
                            <div className={styles.description}>Look at the main screen to see who has joined this team.</div>
                        </div>
                    })
                }
            </div>
        </div>
    }

    clickTeamOption(teamId) {
        if (this.state.submitActive) {
            this.setState({ submitActive: false });
            this.state.room.send("player_selected_team", { teamId })
            setTimeout(() => {
                this.setState({ submitActive: true });
            }, 1000);
        }
    }

    getConfirmTeamView() {
        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                    <Lottie
                        options={getAvatarById(this.state.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={styles.text}>{this.state.player.name}</div>
            </div>
            <div className={styles.subText}>Is everybody happy?</div>
            <div className={styles.buttons}>
                <button className={`${styles.mainButton}`} onClick={() => this.clickTeamConfirmation(true)}>Yes</button>
                <button className={`${styles.mainButton} ${styles.alt}`} onClick={() => this.clickTeamConfirmation(false)}>No</button>
            </div>
        </div>
    }

    getReadyUpView() {
        let isDinger = this.state.player.ycsuData.isDinger;
        let isDescriber = this.state.player.ycsuData.isDescriber;
        let team = this.state.teams.find(x => x.id === this.state.describingTeamId);

        if ((!isDinger && !isDescriber) || !team) return;

        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                    <Lottie
                        options={getAvatarById(this.state.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={styles.text}>{this.state.player.name}</div>
            </div>
            <div className={styles.subText}>{isDinger ? "You will be the dinger this round." : isDescriber ? "You will be the describer this round." : ""}</div>
            <div className={styles.subText}>Are you and your team ready?</div>
            <div className={styles.buttons}>
                <button className={`${styles.mainButton}`} onClick={this.clickReadyUp}>Yes</button>
            </div>
            <div className={styles.textSection}>
                <div className={styles.subText}>{isDinger ? `${team.id}'s rules:` : team.score >= this.state.dangerZoneStart ? "Your rules:" : "Your teams rules:"}</div>
            </div>
            <div className={styles.ruleList}>
                {
                    this.getRuleList()
                }
            </div>
        </div>
    }

    clickReadyUp = () => {
        this.state.room.send("ready_check", {});
    }

    clickTeamConfirmation(happy) {
        if (happy) {
            this.state.room.send("teams_ok", {});
        } else {
            this.state.room.send("teams_not_ok", {});
        }
    }

    getDescriberView() {
        return <div className={styles.dingerSection}>
            {
                this.getDangerZoneNotification()
            }
            <div className={`${styles.timer} ${this.state.timerRunning && styles.show}`}>
                <img className={styles.watchButton} src={stopwatchButton} />
                <div className={styles.faceSection}>
                    <img className={styles.watchFace} src={stopwatchFace} />
                    <div className={styles.faceText}>{this.state.timer}</div>
                </div>
            </div>
            <div className={styles.textSection}>
                <div className={styles.title}>{this.state.player.name}</div>
                <div className={styles.subText}>Describe BOTH words for</div>
                <div className={styles.subText}>your team to guess!</div>
            </div>
            <div className={styles.cardsSection}>
                {
                    this.getCurrentCards()
                }
            </div>
            <div className={styles.buttons}>
                <button className={styles.mainButton} disabled={!this.state.submitActive} onClick={this.clickCorrectGuess}>Correct Guess</button>
                <button className={`${styles.mainButton} ${styles.alt}`} disabled={!this.state.submitActive} onClick={this.clickPass}>Pass</button>
            </div>
        </div>
    }

    clickCorrectGuess = () => {
        if (this.state.submitActive) {
            this.setState({ submitActive: false });
            this.state.room.send("correct_guess");
            setTimeout(() => {
                this.setState({ submitActive: true });
            }, 3000);
        }
    }

    clickPass = () => {
        if (this.state.submitActive) {
            this.setState({ submitActive: false });
            this.state.room.send("pass")
            setTimeout(() => {
                this.setState({ submitActive: true });
            }, 3000);
        }
    }

    getCurrentCards() {
        const team = { ...this.state.teams.find(x => x.id === this.state.describingTeamId) }
        if (!team || !team.currentCards) return;

        let cards = [];
        let count = 0;
        team.currentCards.forEach((x, i) => {
            if (x != null) {
                count++;
                cards.push(<div key={`game-card-${i}-${x.id}`} className={`${styles.card}`}>
                    <div className={styles.letter}>{count === 1 ? "A" : "B"}</div>
                    {`${x.cardText}`}
                </div>)
            }
        });
        return cards;
    }

    getGuessingView() {
        let team = this.state.teams.find(x => x.id === this.state.describingTeamId);
        let describer = this.state.players.find((x) => x.ycsuData.isDescriber);
        if (!team || !describer) return;

        return <div className={styles.dingerSection}>
            {
                this.getDangerZoneNotification()
            }
            <div className={`${styles.timer} ${this.state.timerRunning && styles.show}`}>
                <img className={styles.watchButton} src={stopwatchButton} />
                <div className={styles.faceSection}>
                    <img className={styles.watchFace} src={stopwatchFace} />
                    <div className={styles.faceText}>{this.state.timer}</div>
                </div>
            </div>
            <div className={styles.textSection}>
                <div className={styles.subText}>Guess the words that</div>
                <div className={styles.title}>{describer.name}</div>
                <div className={styles.subText}>is describing.</div>
            </div>
            <div className={styles.textSection}>
                <div className={styles.subText}>{team.score >= this.state.dangerZoneStart ? "Your entire team" : describer.name} must follow {team.rules.length === 1 ? "this rule" : "these rules"}</div>
            </div>
            <div className={styles.ruleList}>
                {
                    this.getRuleList()
                }
            </div>
        </div>
    }

    getRuleList() {
        const team = { ...this.state.teams.find(x => x.id === this.state.describingTeamId) }
        if (!team || !team.rules) return;

        let rules = [];
        team.rules.forEach((x) => {
            if (x != null) {
                rules.push(<div className={`${styles.rule}`}>{`${x.ruleText1} ${x.blockText} ${x.ruleText2}`}</div>)
            }
        });
        return rules;
    }

    getDingerView() {
        let describerTeam = this.state.teams.find(x => x.id === this.state.describingTeamId);
        if (!describerTeam) return;

        return <div className={styles.dingerSection}>
            <div className={`${styles.timer} ${this.state.timerRunning && styles.show}`}>
                <img className={styles.watchButton} src={stopwatchButton} />
                <div className={styles.faceSection}>
                    <img className={styles.watchFace} src={stopwatchFace} />
                    <div className={styles.faceText}>{this.state.timer}</div>
                </div>
            </div>
            {
                this.state.player.ycsuData.isDinger ?
                    <div className={styles.textSection}>
                        <div className={styles.title}>{this.state.players.find((x) => x.ycsuData.isDescriber)?.name}</div>
                        <div className={styles.subText}>is describing.</div>
                        <div className={styles.subText}>Press the bell when</div>
                        <div className={styles.subText}>they break a rule.</div>
                    </div>
                    :
                    <div className={styles.textSection}>
                        <div className={styles.title}>{this.state.players.find((x) => x.ycsuData.isDinger)?.name}</div>
                        <div className={styles.subText}>is the dinger.</div>
                        <div className={styles.subText}>But shout when</div>
                        <div className={styles.subText}>{`${describerTeam.score >= this.state.dangerZoneStart ? describerTeam.id : this.state.players.find((x) => x.ycsuData.isDescriber)?.name} breaks a rule.`}</div>
                    </div>
            }
            {
                this.state.player.ycsuData.isDinger &&
                <div className={styles.bellSection} disabled={!this.state.submitActive} onClick={this.clickDinger}>
                    <img src={dingerBase} className={styles.base} />
                    <img src={dingerDetail} className={styles.detail} />
                    <img key={`bell-top-${this.state.dingCount}`} src={dingerTop} className={styles.top} />
                    <div key={`bell-arc-right-large-${this.state.dingCount}`} className={`${styles.arc} ${styles.right}`}></div>
                    <div key={`bell-arc-right-small-${this.state.dingCount}`} className={`${styles.arc} ${styles.right} ${styles.smaller}`}></div>
                    <div key={`bell-arc-left-large-${this.state.dingCount}`} className={`${styles.arc} ${styles.left}`}></div>
                    <div key={`bell-arc-left-small-${this.state.dingCount}`} className={`${styles.arc} ${styles.left} ${styles.smaller}`}></div>
                </div>
            }
            <div className={styles.ruleList}>
                {
                    this.getRuleList()
                }
            </div>
        </div>
    }

    clickDinger = () => {
        if (this.state.submitActive) {
            let dingCount = this.state.dingCount;
            dingCount++;
            this.setState({ submitActive: false, dingCount, });
            this.state.room.send("ding_ding");
            setTimeout(() => {
                this.setState({ submitActive: true });
            }, 1000);
        }
    }

    getRuleBreakVoteView() {
        let describer = this.state.players.find((x) => x.ycsuData.isDescriber);
        if (!describer) return;

        const currentRuleBreaks = this.state.currentRuleBreaks;

        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                    <Lottie
                        options={getAvatarById(describer.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={styles.text}>{describer.name}</div>
            </div>
            <div className={styles.subText}>{`broke the rules ${currentRuleBreaks === 0 ? "Never" : currentRuleBreaks === 1 ? "Once" : currentRuleBreaks === 2 ? "Twice" : currentRuleBreaks + " Times"}.`}</div>
            <div className={styles.subText}>{`Is this correct?`}</div>
            <div className={styles.voteSection}>
                {
                    this.state.player.ycsuData.ready === false &&
                    <div className={styles.voteButton} disabled={this.state.currentValidationNum === 0} onClick={() => this.state.room.send("rule_break_vote", { increase: false, })}>-</div>
                }
                <div className={styles.voteNumber}>{this.state.currentValidationNum}</div>
                {
                    this.state.player.ycsuData.ready === false &&
                    <div className={styles.voteButton} onClick={() => this.state.room.send("rule_break_vote", { increase: true, })}>+</div>
                }
            </div>
            <div className={styles.buttons}>
                <button className={`${styles.mainButton} ${this.state.player.ycsuData.ready && styles.alt}`} onClick={() => this.state.room.send("lock_in_vote")}>{`${this.state.player.ycsuData.ready ? "Change" : "Correct"}`}</button>
            </div>
        </div>
    }

    getCorrectCardVoteView() {
        const team = { ...this.state.teams.find(x => x.id === this.state.describingTeamId) }
        if (!team) return;

        if (!this.state.currentCorrectPairIds || this.state.currentCorrectPairIds.length === 0) return;
        let correctCardPairs = this.state.currentCorrectPairIds.filter(x => x);

        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div className={styles.text}>{team.id}</div>
            </div>
            <div className={styles.subText}>{`guessed ${correctCardPairs.length / 2} card pairs.`}</div>
            <div className={styles.subText}>{`Is this correct?`}</div>
            <div className={styles.voteSection}>
                {
                    this.state.player.ycsuData.ready === false &&
                    <div className={styles.voteButton} onClick={() => this.state.room.send("correct_card_vote", { increase: false, })}>-</div>
                }
                <div className={styles.voteNumber}>{this.state.currentValidationNum}</div>
                {
                    this.state.player.ycsuData.ready === false &&
                    <div className={styles.voteButton} onClick={() => this.state.room.send("correct_card_vote", { increase: true, })}>+</div>
                }
            </div>
            <div className={styles.buttons}>
                <button className={`${styles.mainButton} ${this.state.player.ycsuData.ready && styles.alt}`} onClick={() => this.state.room.send("lock_in_card_vote")}>{`${this.state.player.ycsuData.ready ? "Change" : "Correct"}`}</button>
            </div>
        </div>
    }

    getNewRuleVoteView() {
        let team = this.state.teams[this.state.player.ycsuData.teamIndex];
        if (!team) return;
        let otherTeam = this.state.teams.find(x => x.id !== team.id);
        if (!otherTeam) return;

        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div id="potato" onClick={this.doAnimation} className={styles.potato}>
                    <Lottie
                        options={getAvatarById(this.state.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={styles.text}>{this.state.player.name}</div>
            </div>
            <div className={styles.subText}>{`Pick a new rule for ${otherTeam.id}`}</div>
            <div className={styles.teamList}>
                {
                    this.getRuleOptions()
                }
            </div>
            <div className={styles.buttons}>
                <button className={styles.mainButton} onClick={this.clickVoteNewRule}>Vote</button>
            </div>
        </div>
    }

    getWinDeciderView() {
        let winningTeam = this.state.teams.find(x => x.id === this.state.winningTeamId);
        if (!winningTeam) return;

        let earnedPoints;
        if (winningTeam.id === this.state.describingTeamId) {
            earnedPoints = this.state.currentCorrectPairIds.length / 2;
        } else {
            earnedPoints = this.state.currentRuleBreaks;
        }

        return <div className={styles.selectTeamSection}>
            <div className={styles.playerSection}>
                <div className={styles.text}>{winningTeam.id}</div>
            </div>
            <div className={styles.subText}>{`earned ${earnedPoints} ${earnedPoints.length === 1 ? "point" : "points"} this round to win.`}</div>
            <div className={styles.subText}>{`Is this correct?`}</div>
            <div className={styles.voteSection}>
                {
                    this.state.player.ycsuData.ready === false &&
                    <div className={styles.voteButton} disabled={this.state.currentValidationNum === 0} onClick={() => this.state.room.send("win_decide_vote", { increase: false, })}>-</div>
                }
                <div className={styles.voteNumber}>{this.state.currentValidationNum}</div>
                {
                    this.state.player.ycsuData.ready === false &&
                    <div className={styles.voteButton} onClick={() => this.state.room.send("win_decide_vote", { increase: true, })}>+</div>
                }
            </div>
            <div className={styles.buttons}>
                <button className={`${styles.mainButton} ${this.state.player.ycsuData.ready && styles.alt}`} onClick={() => this.state.room.send("lock_in_win_decide_vote")}>{`${this.state.player.ycsuData.ready ? "Change" : "Correct"}`}</button>
            </div>
        </div>
    }

    clickVoteNewRule = () => {
        if (this.state.voteRuleId != null) {
            const ruleIds = this.state.currentRulesVote.filter(x => x != null).map(x => x.id);

            if (ruleIds.includes(this.state.voteRuleId)) {
                this.state.room.send("rule_vote", { ruleId: this.state.voteRuleId });
            }
        }
    }

    getRuleOptions() {
        let count = 0;
        return this.state.currentRulesVote.filter(x => x).map((x, i) => {
            //if (x === null || x === undefined) return;
            //console.log("x : ", x);
            count++;

            return <div className={`${styles.teamOption} ${[this.state.player.ycsuData.currentRuleVoteId, this.state.voteRuleId].includes(x.id) && styles.selected}`} onClick={() => this.setState({ voteRuleId: x.id })}>
                <div className={styles.title}>{`OPTION ${count}`}</div>
                <div className={styles.description}>{`${x.ruleText1} ${x.blockText} ${x.ruleText2}`}</div>
            </div>
        });
    }

    getDangerZoneNotification() {
        let team = this.state.teams[this.state.player.ycsuData.teamIndex];
        let describingTeam = this.state.teams.find(x => x.id === this.state.describingTeamId);
        let isDescriber = this.state.player.ycsuData.isDescriber;

        if (!team || !describingTeam || team.id !== describingTeam.id || isDescriber) return;

        if (describingTeam.score >= this.state.dangerZoneStart) {
        return <div className={styles.dangerZoneNotification}>
            <div className={styles.text}>DANGER ZONE</div>
            <div className={styles.text}>Your entire team must follow the rules.</div>
        </div>
        }
    }


    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }

        const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer"];
        changes.forEach(change => {
            player.listen(change, (value) => {
                console.log(`Player ${player.name} Change ${change} to Value ${value}`);
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };

                statePlayer[change] = value;

                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            });
        });

        const ycsuPlayerChanges = ["teamIndex", "isDescriber", "isDinger", "ready", "currentRuleVoteId",];
        ycsuPlayerChanges.forEach(change => {
            player.ycsuData.listen(change, (value) => {
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };

                statePlayer.ycsuData[change] = value;

                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            });
        });
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }


    setupStateListeners(state) {
        state.players.onAdd((player, key) => {
            console.log(player, "has been added at", key);
            this.checkAndAddPlayer(player);

            if (player.id === this.state.myId) {
                const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer"];
                changes.forEach(change => {
                    player.listen(change, (value) => {
                        let statePlayer = { ...this.state.player };
                        statePlayer[change] = value;

                        this.setState({ player: statePlayer });
                    });
                });

                const ycsuPlayerChanges = ["teamIndex", "isDescriber", "isDinger", "ready", "currentRuleVoteId",];
                ycsuPlayerChanges.forEach(change => {
                    player.ycsuData.listen(change, (value) => {
                        let statePlayer = { ...this.state.player };
                        statePlayer.ycsuData[change] = value;

                        this.setState({ player: statePlayer });
                    });
                });
            }
        });

        const ycsuStateVars = ["gameState", "roundNumber", "timer", "newRuleTeamId", "teamSelectionType", "describingTeamId", "currentRuleBreaks", "currentValidationNum", "pointsToWin", "dangerZoneStart", "winningTeamId", "clientDescriberState"];
        ycsuStateVars.forEach((stateVar) => {
            state.ycsuData.listen(stateVar, (change) => {
                let update = {};
                update[stateVar] = change;
                this.setState(update);
            });
        });
        const ycsuStateArrayChanges = ["currentCorrectPairIds", "newRuleZones"];
        ycsuStateArrayChanges.forEach(arrayName => {
            let update = {};
            update[arrayName] = [];
            this.setState(update);
            state.ycsuData[arrayName].onAdd((change, index) => {
                console.log(`YCSU Array ${arrayName} Change ${change} at index ${index}`);
                let array = [...this.state[arrayName]];
                array[index] = change;
                let changeUpdate = {};
                changeUpdate[arrayName] = array;
                this.setState(changeUpdate);
            });
            state.ycsuData[arrayName].onRemove((change, index) => {
                console.log(`YCSU Array ${arrayName} Removed ${change} at index ${index}`);
                let array = [...this.state[arrayName]];
                array[index] = null;
                let changeUpdate = {};
                changeUpdate[arrayName] = array;
                this.setState(changeUpdate);
            });
        });

        state.ycsuData.teams.onAdd((team, index) => {
            console.log("New Team : ", team.id);
            let teams = [...this.state.teams];
            teams[index] = { id: "", colour: "", score: 0, totalRuleBreaks: 0, totalCorrectPairs: 0, playerIds: [], currentCards: [], rules: [] };
            this.setState({ teams });

            setTimeout(() => {

                const teamchanges = ["id", "colour", "score", "totalRuleBreaks", "totalCorrectPairs",];
                teamchanges.forEach(change => {
                    team.listen(change, (value) => {
                        console.log(`New Team Change : ${change} as ${value}`);
                        let stateTeams = [...this.state.teams];
                        let stateTeam = { ...stateTeams[index] };
                        stateTeam[change] = value;
                        stateTeams[index] = stateTeam;
                        this.setState({ teams: stateTeams });
                    });
                });

                team.playerIds.onChange((change, playerIndex) => {
                    console.log(`Team ${team.id} changed Player Id : ${change} at index ${playerIndex}`);
                    let stateTeams = [...this.state.teams];
                    let stateTeam = { ...stateTeams[index] };
                    stateTeam["playerIds"][playerIndex] = change;
                    console.log("State Team Player Ids : ", stateTeam["playerIds"]);
                    stateTeams[index] = stateTeam;
                    this.setState({ teams: stateTeams });
                });
                team.currentCards.onAdd((card, cardIndex) => {

                    if (card) {
                        console.log(`Team ${team.id} added new Card : ${card.id} at index ${cardIndex}`)
                        let stateTeams = [...this.state.teams];
                        let stateTeam = { ...stateTeams[index] };
                        stateTeam["currentCards"][cardIndex] = card;
                        stateTeams[index] = stateTeam;
                        this.setState({ teams: stateTeams });
                        const cardChanges = ["id", "cardText"];
                        cardChanges.forEach(change => {
                            card.listen(change, (value) => {
                                console.log(`card change at index ${cardIndex} : ${change} : ${value}`);
                                let cardStateTeams = [...this.state.teams];
                                let cardStateTeam = { ...cardStateTeams[index] };
                                let currCards = [...cardStateTeam.currentCards];
                                //console.log("Current Cards : ", currCards);
                                //console.log("Card : ", card);
                                let currCard = { ...currCards[cardIndex] };
                                currCard[change] = value;
                                currCards[cardIndex] = currCard;
                                cardStateTeam.currentCards = currCards;
                                cardStateTeams[index] = cardStateTeam;
                                this.setState({ teams: cardStateTeams });
                            });
                        });
                    }
                });
                team.currentCards.onRemove((card, index) => {
                    console.log(`Team ${team.id} Removed Card : ${card.id} at index ${index}`)
                    let stateTeams = [...this.state.teams];
                    let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                    let stateTeam = { ...stateTeams[pos] };
                    stateTeam["currentCards"][index] = null;
                    stateTeams[pos] = stateTeam;
                    this.setState({ teams: stateTeams });
                })
                team.rules.onAdd((rule, index) => {
                    console.log(`Team ${team.id} added new Rule ${rule.id} at index ${index}`);
                    let stateTeams = [...this.state.teams];
                    let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                    let stateTeam = { ...stateTeams[pos] };
                    stateTeam["rules"][index] = rule;
                    stateTeams[pos] = stateTeam;
                    this.setState({ teams: stateTeams });

                    const ruleChanges = ["id", "ruleText1", "blockText", "ruleText2"];
                    ruleChanges.forEach(change => {
                        rule.listen(change, (value) => {
                            let ruleStateTeams = [...this.state.teams];
                            let ruleStateTeam = { ...ruleStateTeams[pos] };
                            let currRules = [...ruleStateTeam.rules];
                            let currRule = { ...currRules[index] };
                            currRule[change] = value;
                            currRules[index] = currRule;
                            ruleStateTeam.rules = currRules;
                            ruleStateTeams[pos] = ruleStateTeam;
                            this.setState({ teams: ruleStateTeams });
                        });
                    });
                });
                team.rules.onRemove((rule, index) => {
                    console.log(`Team ${team.id} Removed Rule ${rule.id} at index ${index}`);
                    let stateTeams = [...this.state.teams];
                    let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                    let stateTeam = { ...stateTeams[pos] };
                    stateTeam["rules"][index] = null;
                    stateTeams[pos] = stateTeam;
                    this.setState({ teams: stateTeams });
                });

            }, 100);
        });
        state.ycsuData.teams.onRemove((team, index) => {
            console.log("Team Removed : ", team.id);
            let teams = [...this.state.teams];
            teams[index] = null;
            this.setState({ teams });
        })

        state.ycsuData.currentRulesVote.onAdd((rulesVote, index) => {
            console.log("New rulesVote : ", rulesVote.id);
            let currentRulesVote = [...this.state.currentRulesVote];
            currentRulesVote[index] = rulesVote;
            this.setState({ currentRulesVote });

            const rulesVoteChanges = ["id", "ruleText1", "blockText", "ruleText2"];
            rulesVoteChanges.forEach(change => {
                rulesVote.listen(change, (value) => {
                    console.log(`New Rules Vote Change : ${change} as ${value}`);
                    let stateRulesVotes = [...this.state.currentRulesVote];
                    let stateRulesVote = { ...stateRulesVotes[index] };
                    stateRulesVote[change] = value;
                    stateRulesVotes[index] = stateRulesVote;
                    this.setState({ currentRulesVote: stateRulesVotes });
                });
            });
        });
        state.ycsuData.currentRulesVote.onRemove((rulesVote, index) => {
            console.log("rulesVote Removed: ", rulesVote.id);
            let currentRulesVote = [...this.state.currentRulesVote];
            currentRulesVote[index] = null;
            this.setState({ currentRulesVote });
        })
    }


    doReconnect = () => {
        const token = this.getQueryStringValue("token");

        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                this.setState({ isPaused: state.isPaused, });

                this.startLocationChecks();
                this.setupStateListeners(state);
            });

            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId != gameId) {
                    this.goToLobby();
                }
            });
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("location_confirmed", (message) => {
                console.log("location_confirmed", "received on", room.name, message);
                this.setState({ gotLocationPing: true, });
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);

                if (!this.state.redirectURL) {
                    if (code == 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={`${styles.clientContainer} ${this.state.player.ycsuData.teamIndex != null ? this.state.player.ycsuData.teamIndex === 0 ? styles.teamA : styles.teamB : null}`}>
                            {
                                this.state.player.ycsuData.teamIndex != null && this.state.player.ycsuData.teamIndex >= 0 &&
                                <img className={styles.teamBG} src={this.state.player.ycsuData.teamIndex == 0 ? MobilePinkBG : MobileBlackBG} />
                            }
                            {
                                this.state.connectionIssue &&
                                <div className={styles.connectionIssueContainer}>
                                    <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                    <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                </div>
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                           
                            {
                                this.getRenderView()
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
